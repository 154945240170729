const colors = require('tailwindcss/colors')

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
  ],
  safelist: [
    'bg-cyan-100',
    'bg-red-600',
  ],
  theme: {
    fontFamily: {
      sans: ['Poppins', 'sans-serif']
    },
    container: {
      center: true,
      padding: {
        DEFAULT: '0.75rem',
        lg: '2rem',
        xl: '2rem',
        '2xl': '3rem',
      },
    },
    colors: {
        transparent: 'transparent',
        black: colors.black,
        white: colors.white,
        yellow: colors.yellow,
        red: colors.red,
        blue: '#033B61',
        darkblue: '#012F4F',
        cyan: {
          50: '#F8FDFF',
          100: '#DDF7FF',
          150: '#F2FBFE',
          200: '#4DC6F4',
          DEFAULT: '#00AEEF',
        },
        gray: {
          10: '#F9FBFC',
          25: '#F9FAFB',
          50: '#F5F7F9',
          100: '#E2ECF2',
          200: '#D2E0E8',
          400: '#C3D4DD',
          600: '#7DA0B2',
          800: '#5A7C8D',
          DEFAULT: '#5A7C8D',        
        },
        product: '#62C8CF',
        safety: '#F35B76',
        regulatory: '#A96EE8',
    },  
    extend: {
      spacing: {
        header: '5rem',
        sideScrollable: '150vw'
      },
      boxShadow: {
        card: '0px 0px 8px 0px #0000001A',
      },
      fontSize: {
        error: '12.5rem',
      },
    },
  },
  plugins: [
    require('@tailwindcss/typography'),
    require('@headlessui/tailwindcss'),
  ],
}
