import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { ReactComponent as IconAttention } from "../../../assets/icons/Icon_Attation.svg";
import "./RegulatoryInfosCard.scss";
import PrimaryButton from "../../elements/PrimaryButton";
import { GHSPictograms } from "./GHSPictograms";


const InfoDataRow = (props) => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const valueList = props.values?.map((element, index) => {
    return (
      <div
        key={index}
        className={
          (props.tags
            ? "bg-gray-100 rounded-sm text-sm py-[0.125rem] px-2"
            : "") +
          (props.values?.length > 1 && !props.tags
            ? " border border-gray-100 rounded  text-sm py-[0.125rem] px-2"
            : "")
        }
      >
        {element}
      </div>
    );
  });

  if (valueList?.length > 0 || props.forceShow) {
    return (
      <div className="relative py-3 infoRow">
        <div className="flex w-full gap-1 lg:gap-6 max-lg:flex-col lg:items-center flex-nowrap">
          <p className="font-medium lable basis-1/5 text-md">
            {props.label}
          </p>
          <div className="flex flex-wrap items-center gap-2 font-normal data basis-4/5 text-md text-gray">
            {valueList?.length > 0 && valueList}
            {valueList?.length === 0 && (
              <span className="noData">No data found</span>
            )}
            {props.disclaimer && (
              <div
                className="text-red-600"
                onMouseEnter={() => setShowDisclaimer(true)}
                onMouseLeave={() => setShowDisclaimer(false)}
              >
                <IconAttention className="w-5" />
                {showDisclaimer && (
                  <div className="absolute left-0 z-10 w-full -mt-3 top-full bg-red-50">
                    <div className="p-2 text-sm bg-white rounded text-blue shadow-card">
                      {props.disclaimer}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const RegionInfoDataRow = (props) => {
  return (
    <div className="relative py-3 infoRow">
      <div className="flex w-full gap-1 lg:gap-6 max-lg:flex-col flex-nowrap">
        <p className="font-medium lable basis-1/5 text-md">
          {props.label}
        </p>
        <div className="flex flex-col gap-2 font-normal data basis-4/5 text-md text-gray">
          {(props.values_eu.length === 0 &&
            props.values_us.length === 0 &&
            props.values_cn.length === 0 &&
            props.values_ghs.length === 0) ? (
            <span className="noData">No data found</span>
          ) : (
            <>
              {props.values_eu.length > 0 && (
                <div className="flex gap-2">
                  <p className="font-semibold">
                    EU:
                  </p>
                  <span className="font-normal">
                    {props.values_eu.join(", ")}
                  </span>
                </div>
              )}
              {props.values_us.length > 0 && (
                <div className="flex gap-2">
                  <p className="font-semibold">
                    US:
                  </p>
                  <span className="font-normal">
                    {props.values_us.join(", ")}
                  </span>
                </div>
              )}
              {props.values_cn.length > 0 && (
                <div className="flex gap-2">
                  <p className="font-semibold">
                    CN:
                  </p>
                  <span className="font-normal">
                    {props.values_cn.join(", ")}
                  </span>
                </div>
              )}
              {props.values_ghs.length > 0 && (
                <div className="flex gap-2">
                  <p className="font-semibold">
                    GHS:
                  </p>
                  <span className="font-normal">
                    {props.values_ghs.join(", ")}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const GHSSymbolsRow = (props) => {
  return (
    <div className="relative py-3 infoRow">
      <div className="flex w-full gap-1 lg:gap-6 max-lg:flex-col lg:items-start flex-nowrap">
        <p className="font-medium lable basis-1/5 text-md">
          {props.label}
        </p>
        <div className="flex flex-col font-normal data basis-4/5 text-md text-gray">
          {(props.values_eu.length === 0 &&
            props.values_us.length === 0 &&
            props.values_cn.length === 0 &&
            props.values_ghs.length === 0) ? (
            <span className="noData">No data found</span>
          ) : (
            <>
              {props.values_eu.length > 0 && (
                <div className="mb-2">
                  <p className="w-10 font-semibold pt-3">
                    EU:
                  </p>
                  <GHSPictograms pictograms={props.values_eu} />
                </div>
              )}
              {props.values_us.length > 0 && (
                <div className="mb-2">
                  <p className="w-10 font-semibold pt-3">
                    US:
                  </p>
                  <GHSPictograms pictograms={props.values_us} />
                </div>
              )}
              {props.values_cn.length > 0 && (
                <div className="mb-2">
                  <p className="w-10 font-semibold pt-3">
                    CN:
                  </p>
                  <GHSPictograms pictograms={props.values_cn} />
                </div>
              )}
              {props.values_ghs.length > 0 && (
                <div className="mb-2">
                  <p className="w-10 font-semibold pt-3">
                    GHS:
                  </p>
                  <GHSPictograms pictograms={props.values_ghs} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
};

const RegulatoryInfosCard = (props) => {

  const ATTRIBUTES = [
    "cas_number",
    "inci",
    "eu_number",
    "eu_name",
    "reach_number",
    "ris_tags",
    "signal_eu",
    "signal_us",
    "signal_cn",
    "signal_ghs",
    "h_statement_eu",
    "h_statement_us",
    "h_statement_cn",
    "h_statement_ghs",
    "ghs_symbols_eu",
    "ghs_symbols_us",
    "ghs_symbols_cn",
    "ghs_symbols_ghs",
    "packaging",
  ];
  const location = useLocation();

  let count = 0;
  for (let attr of ATTRIBUTES) {
    count += props.product[attr]?.length;
  }

  if (count === 0) {
    return (
      <div className="p-6 border border-gray-100">
        <p className="mb-4">
          Unfortunately there is no Regulatory Information available
          at the moment. In case of questions please reach out to your
          Sasol sales representative via our contact form.
        </p>
        <PrimaryButton title="Support" color="blue" onClick={null} />
      </div>
    );
  }

  return (
    <div className="flex flex-col regulatoryInfos">
      <p className="mb-4 text-sm font-semibold">
        The regulatory information displayed is consolidated for a
        product and may vary between different specifications. For more
        information please reach out to your Sasol sales representative
        via our{" "}
        <Link
          to="/contact"
          state={{ backLink: location }}
          className="underline"
        >
          contact form
        </Link>
        .
      </p>

      <InfoDataRow
        label={"CAS Number"}
        values={props.product.cas_number ?? []}
        forceShow={true}
      />
      <InfoDataRow
        label={"INCI"}
        values={props.product.inci ?? []}
        forceShow={true}
      />
      <InfoDataRow
        label={"EC / List Number"}
        values={props.product.eu_number ?? []}
        forceShow={true}
      />
      <InfoDataRow
        label={"EC Name"}
        values={props.product.eu_name ?? []}
        forceShow={true}
      />
      <InfoDataRow
        label={"REACH Number"}
        values={props.product.reach_number ?? []}
        forceShow={true}
      />
      <InfoDataRow
        label={"RIS Tags"}
        values={props.product.ris_tags ?? []}
        tags={true}
        forceShow={true}
      />
      <InfoDataRow
        label={"Packaging"}
        values={props.product.packaging ?? []}
        disclaimer={
          <p>
            Please be aware that not all packaging types are always
            available.
            <br />
            For more details on availability please reach out to
            your Sasol sales representative via our contact form
          </p>
        }
        forceShow={true}
      />
      <RegionInfoDataRow
        label={"Signal"}
        values_eu={props.product.signal_eu ?? []}
        values_us={props.product.signal_us ?? []}
        values_cn={props.product.signal_cn ?? []}
        values_ghs={props.product.signal_ghs ?? []}
      />
      <RegionInfoDataRow
        label={"H Statement"}
        values_eu={props.product.h_statement_eu ?? []}
        values_us={props.product.h_statement_us ?? []}
        values_cn={props.product.h_statement_cn ?? []}
        values_ghs={props.product.h_statement_ghs ?? []}
      />
      <GHSSymbolsRow
        label={"GHS Pictograms"}
        values_eu={props.product.ghs_symbols_eu ?? []}
        values_us={props.product.ghs_symbols_us ?? []}
        values_cn={props.product.ghs_symbols_cn ?? []}
        values_ghs={props.product.ghs_symbols_ghs ?? []}
      />
    </div>
  );
};

RegulatoryInfosCard.propTypes = {
  /**
   * Product object with rgulatory info data
   */
  product: PropTypes.object,
};

export default RegulatoryInfosCard;
